<template>
  <section class="section feature feature-center pt-50 pb-50" v-if="productInfo">
    <div class="container">
      <div class="row" v-if="!productInfo.productFound">
        <div class="col-12 col-md-12 col-lg-7 offset-lg-2">
          <div class="heading heading-1 text--center mb-50">
            <h2 class="heading-no-result">Are you sure you entered the right ASIN? We can't seem to find it right now on Amazon.</h2>
          </div>
          <div class="hero-img text-center">
            <img class="img-fluid no-result-img" src="@/assets/images/asin-index-checker-no-results.svg" alt="No results" />
          </div>
        </div>
      </div>

      <div class="row" v-if="productInfo.productFound">
        <div class="col-12">
          <h3 class="heading-result">Your results for:</h3>

          <div class="row">
            <div class="col-sm-8 offset-sm-2 offset-md-0 col-md-5 col-lg-3"> 
                <div class="image-box">
                  <a class="product-link" :href="productUrl" target="_blank">
                    <img :src="productInfo.imageUrl" :alt="productInfo.title" />
                  </a>
                </div>
            </div>
            <div class="col-md-7 col-lg-9">
              <div class="product-title text--left">
                <a class="product-link" :href="productUrl" target="_blank">
                  {{productInfo.title}}
                </a>
              </div>
              
              <div class="row product-info">
                <div class="col-6">
                  <div class="info-title">ASIN</div>
                  <div class="info-value">{{productInfo.asin}}</div>
                </div>
                <div class="col-6">
                  <div class="info-title">Market</div>
                  <div class="info-value">
                    <img :src="marketFlag" alt="Market flag" class="market-flag" />
                    {{market.name}}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12 col-md-12 col-lg-7 offset-lg-2 keyword-results">
          <div class="table-box">
            <div class="row">
              <div class="col-9 info-title">
                Keyword
              </div>
              <div class="col-3 info-title">
                Indexed
              </div>
            </div>
            <hr class="mt-10 mb-20" />
            <div class="table-box-scroll" v-slimscroll="{ height: '300px' }">
              <div class="row" v-for="kwObj in productInfo.keywords" :key="kwObj.keyword">
                <div class="col-9 indexed-keyword">
                  {{kwObj.keyword}}
                </div>
                <div class="col-3 indexed-value">
                  <span class="fa" :class="getKeywordClasses(kwObj)"></span>
                </div>
              </div>
            </div>
          </div>

          <div class="export-button-container">
            <button @click.prevent="exportCSV"  class="btn btn--gradient btn--gradient--white" :disabled="!allKeywordsLoaded()">
              <i class="fa fa-file-excel-o"></i> EXPORT .CSV
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import MarketService from "../services/market.service";

export default {
  name: "AppIndexCheckerResult",
  props: {
    productInfo: {
      type: Object,
      required: false
    },
  },
  computed: {
    productUrl() {
      return MarketService.getProductUrl(this.productInfo.market, this.productInfo.asin);
    },
    market() {
      return MarketService.getMarket(this.productInfo.market);
    },
    marketFlag() {
      return MarketService.getFlagUrl(this.market);
    }
  },
  methods: {
    exportCSV() {
      if (!this.productInfo.keywords || !this.productInfo.keywords.length) {
        return false;
      }

      if (!this.allKeywordsLoaded()) {
        return false;
      }

      // Generate csv content
      let csvLines = ['"Market","ASIN","Keyword","Indexed"'];

      this.productInfo.keywords.forEach(kwObj => {
        let kwCsvValue = kwObj.keyword.replace(/"/g, '""');
        let indexedCsvValue = kwObj.isIndexed ? "True" : "False";
        csvLines.push(`"${this.productInfo.market}","${this.productInfo.asin}","${kwCsvValue}","${indexedCsvValue}"`);
      });

      // No data
      if (csvLines.length <= 1) {
        return;
      }

      let csvContent = csvLines.join("\r\n");

      // Download csv
      let downloadLink = document.createElement("a");
      downloadLink.download = `ZonGuru_Index_Checker_Results_${this.productInfo.market}_${this.productInfo.asin}.csv`;
      downloadLink.href = window.URL.createObjectURL(new Blob(["\ufeff", csvContent], { type: "text/csv" }));
      downloadLink.style.display = "none";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
    getKeywordClasses(kwObj) {
      let classObj = {
        'fa-check': kwObj.isIndexed === true, 
        'fa-times': kwObj.isIndexed === false, 
        'indexed': kwObj.isIndexed === true,
        'fa-spin': kwObj.isIndexed === null,
        'fa-spinner': kwObj.isIndexed === null
      };

      return classObj;
    },
    allKeywordsLoaded() {
      return this.productInfo.keywords.every(kwObj => kwObj.isIndexed !== null);
    }
  }
};
</script>

<style scoped lang="scss">

.no-result-img {
  width: 500px;
  max-width: 100%;
}

.heading-no-result {
  font-weight: 500;
  line-height: 30px;
  font-size: 20px;
  text-align: center;
  color: #6C6C6C;
}

.heading-result {
  font-weight: 900;
  font-size: 20px;
  line-height: 32px;
  text-align: left;
  color: #FFBF3C;
}

.image-box {
  box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  padding-top: 5%;
  padding-bottom: 5%;
  background-color: white;
}

.product-title {
  margin-top: 10px;
  text-align: left;

  @media (min-width: 992px) {
    margin-top: 40px
  }

  .product-link {
    font-weight: bold;
    line-height: 27px;
    font-size: 16px;
    color: #6C6C6C;

    &:hover {
      text-decoration: underline;
    }
  }
}

.product-link:hover {
  cursor: pointer;
}

.product-info {
  margin-top: 35px;

  @media (min-width: 992px) {
    margin-top: 50px
  }
}

.info-title {
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: #FFAF25;
  text-transform: uppercase;
}

.info-value {
  text-align: left;
  font-weight: 600;
  font-size: 18px;
  text-transform: uppercase;
  color: #2D7A63;
}

.market-flag {
  width: 30px;
  height: 20px;
  margin-right: 5px;
  margin-top: -3px;
}

.keyword-results {
  margin-top: 50px;
  
  @media (min-width: 992px) {
    margin-top: 100px
  }
}

.table-box {
  background: #FFFFFF;
  box-shadow: 4px 4px 14px rgba(0, 0, 0, 0.09);
  border-radius: 5px;

  padding: 20px 30px 20px 30px;
}

.indexed-keyword {
  text-align: left;
  font-style: italic;
  font-weight: bold;
  font-size: 22px;
  line-height: 35px;
  color: #2D7A63;
}

.indexed-value {
  text-align: left;
  padding-left: 30px;
  
  .fa {
    font-size: 20px;
    margin-top: 10px;

    color: #FF2A07;

    &.fa-spin {
      color: #2D7A63;
    }

    &.indexed {
      color: #5CBC95
    }
  }
}

.export-button-container {
  margin-top: 30px;
  text-align: right;

  .btn {
    width: 170px;
    height: 50px;
    font-size: 12px;
    line-height: 14px;

    .fa {
      margin-right: 7px;
      font-size: 18px;
    }
  }
}
</style>