<template>
  <div id="app" class="wrapper clearfix">
    <AppHeader></AppHeader>
    <AppIndexChecker></AppIndexChecker>
    <AppAdvertisement></AppAdvertisement>
    <AppTestimonials></AppTestimonials>
    <AppFooter></AppFooter>
  </div>
</template>

<script>
import AppHeader from "./components/AppHeader.vue";
import AppIndexChecker from "./components/AppIndexChecker.vue";
import AppAdvertisement from "./components/AppAdvertisement.vue";
import AppTestimonials from "./components/AppTestimonials.vue";
import AppFooter from "./components/AppFooter.vue";

export default {
  name: "app",
  components: {
    AppHeader,
    AppIndexChecker,
    AppAdvertisement,
    AppTestimonials,
    AppFooter
  }, 
  mounted() {
    this.$intercom.boot();
    this.$intercom.trackEvent('visited-index-checker-lead-magnet');
  }
};
</script>

<style lang="scss">
html,
body {
  padding: 0;
  margin: 0;
}

#body {
  .vld-overlay.is-full-page {
    z-index: 99999;
  }

  #app {
    font-family: "Roboto", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    margin: 0 auto;
    color: #2c3e50;

    background-image: url(./assets/images/background/bg-1.png);
    background-repeat: no-repeat;
  }

  .form-control {
    color: #1a1e1f;
    padding-left: 22px;
    width: 100%;
    max-width: 100%;

    &:focus {
      border-color: #ffaf25;
    }
  }

  .form-error {
    width: 100%;
    font-size: 12px;
    margin-top: 4px;
    text-align: left;
    color: #dc3545;
    padding-left: 22px;
  }

  select.form-control {
    height: 55px;
  }

  textarea.form-control {
    height: 250px;
    line-height: 20px;
    padding: 22px;
  }

  .btn--gradient {
    background: #ffbf3c;

    &:focus,
    &:active,
    &:visited {
      box-shadow: 0 0 0 0.2rem rgba(255, 187, 53, 0.25);
    }

    &--white {
      color: #ffaf25;
      background: #ffffff;
      border: 2px solid #ffbf3c;
      box-shadow: none;
    }
  }

  .btn[disabled] {
    opacity: 0.5;
    cursor: not-allowed;
  }

  .pl-10 {
    padding-left: 10px;
  }

  .pr-10 {
    padding-right: 10px;
  }

  ::selection {
    color: white;
    background-color: #ffaf25;
  }

  .modal-backdrop {
    opacity: 0.3;
  }
}
</style>
