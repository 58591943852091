<template>
  <b-modal
    v-model="showModal"
    :hide-footer="true"
    :hide-header="true"
    :no-close-on-backdrop="true"
    size="lg"
    centered
  >
    <div class="email-modal-body-container">
      <div class="row">
        <div class="col-md-12 text-center">
          <div class="modal-title">Like what you see?</div>
          <div class="modal-desc">ZonGuru has much more to offer. If you'd like to learn more about software built by actual Amazon sellers then let us know how to reach you!</div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12">
          <div class="hero-action heading heading-1">
            <form
              class="form-inline mailchimp email-modal-form"
              novalidate="true"
              @submit.prevent="onSubmit"
            >
              <div class="col-12 col-md-6 col-lg-6 offset-lg-1">
                <input
                  type="text"
                  v-model="email"
                  class="form-control"
                  autofocus
                  placeholder="Type e-mail here..."
                  :class="{ 'is-invalid': errors.email }"
                />
                <div class="form-error" v-if="errors.email">{{errors.email}}</div>
              </div>
              <div class="col-12 col-md-6 col-lg-5">
                <input type="submit" value="SEND IT" class="btn btn--gradient" />
                <div class="form-error" v-if="errors.email">&nbsp;</div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-md-12 text-center">
          <a class="email-modal-cancel-button" @click="hide">No thanks, I don't want to check more ASINs</a>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
let debounce = require("debounce");

import { BModal } from "bootstrap-vue";

export default {
  name: "AppEmailModal",
  components: {
    "b-modal": BModal
  },
  data: function() {
    return {
      showModal: false,
      submitted: false,
      email: null,
      errors: {
        email: null
      }
    };
  },
  methods: {
    show() {
      // Reset
      this.submitted = false;
      this.email = null;
      this.errors.email = null;

      this.showModal = true;
    },
    hide() {
      this.showModal = false;
    },
    onSubmit() {
      this.submitted = true;

      if (!this.validateEmail()) {
        return;
      }

      this.$emit('subscribed', this.email);
      this.hide();
    },
    validateEmail() {
      // basic regex for email validaiton, no need to be super strict here
      let emailValid = this.email && /^(.+)@(.+){2,}\.(.+){2,}$/.test(this.email);
      if (!emailValid) {
        this.errors.email = 'Please enter a valid e-mail';
        return false;
      }

      this.errors.email = null;
      return true;
    },
  },
  watch: {
    email: function() {
      if (this.email) {
        debounce(this.validateEmail(), 300);
      }
    }
  }
};
</script>

<style scoped lang="scss">
.email-modal-body-container {
  padding: 40px 30px 30px 30px;
  .modal-title {
    line-height: 45px;
    font-size: 32px;
    text-align: center;
    color: #2d7a63;
  }

  .modal-desc {
    margin-top: 20px;
    line-height: 28px;
    font-size: 18px;
    text-align: center;
    color: #6c6c6c;
  }

  .email-modal-form {
    margin: 40px 0px 30px 0px;

    [class*="col"] {
      margin-top: 10px;
    }
  }

  .email-modal-cancel-button {
    font-size: 16px;
    text-align: center;
    text-decoration: underline;
    color: #6c6c6c;

    &:hover {
      cursor: pointer;
      text-decoration: underline;
      color: #6c6c6c;
    }
  }
}
</style>