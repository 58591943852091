<template>
<footer class="footer">
  <div class="footer-top">
    <div class="container">
      <div class="row">
        <div class="col-12 col-md-12 col-lg-4">
          <div class="footer-logo">
            <img src="@/assets/images/logo-home.svg" width="70" alt="ZonGuru Logo">
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-8">
          <div class="footer-menu">
            <a href="https://zonguru.com/pricing/">Pricing</a>
            <a href="https://zonguru.com/about-us/">About</a>
            <a @click="contactUs">Contact</a>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="footer-bottom">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-12">
          <hr>
        </div>
        <div class="col-12 col-md-12 col-lg-12">
          <div class="footer-copyright">
            <span>&copy; ZonGuru {{new Date().getFullYear()}}, All Rights Reserved.</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</footer>

</template>


<script>
export default {
  name: "AppFooter",
  methods: {
    contactUs() {
      this.$intercom.hide();
      this.$intercom.show();
    }
  }
};
</script>

<style scoped lang="scss">
footer .footer-top {
  padding: 80px 0 0 0;

  .footer-menu a {
    font-weight: 500;
    line-height: 20px;
    font-size: 12px;
    text-align: center;
    color: #6C6C6C;
    cursor: pointer;
  }
}
</style>