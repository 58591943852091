<template>
  <section class="section hero">
    <div class="container">
      <div class="row row-content">
        <div class="col-12 col-md-12 col-lg-6">
          <div class="feature-panel">
            <div class="feature-content">
              <h3>ASIN Index Checker</h3>
              <p>Make sure your customers on Amazon can see you with this FREE tool by ZonGuru. Index check your ASINs for any keyword on Amazon and never get left in the dark again.</p>
              <ul class="text--left">
                <li>Make sure your product is indexed for relevant keywords so your customers can find you</li>
                <li>Greater visibility = More Sales</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-12 col-md-12 col-lg-6">
          <div class="hero-img">
            <img
              class="img-fluid"
              src="@/assets/images/asin-index-illustration.png"
              alt="background"
            />
          </div>
        </div>
      </div>
      <div class="row mt-80">
        <div class="col-12 col-md-12 col-lg-7 offset-lg-2">
          <div class="heading heading-1 text--center mb-40">
            <h3 class="heading-desc">
              ASIN Index Checker Shows You If Amazon Has Indexed Your ASIN for Specific Keywords
              <p class="heading-desc-instructions">Type the ASIN and keywords you want us to check below to get started!</p>
            </h3>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-md-12 col-lg-7 offset-lg-2">
          <div class="hero-action heading heading-1 text--center">
            <AppIndexCheckerForm 
              @new-product-info-result="onNewProductInfoResult"
              @new-keyword-result="onNewKeywordResult"
            ></AppIndexCheckerForm>
          </div>
        </div>
        <div class="col-12">
          <div id="index-checker-result-scroll-anchor"></div>
          <AppIndexCheckerResult :product-info="productInfoResult"></AppIndexCheckerResult>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
var VueScrollTo = require('vue-scrollto');

import AppIndexCheckerForm from "./AppIndexCheckerForm.vue";
import AppIndexCheckerResult from "./AppIndexCheckerResult.vue";

export default {
  name: "AppIndexChecker",
  components: {
    AppIndexCheckerForm,
    AppIndexCheckerResult
  },
  data: function() {
    return {
      productInfoResult: null,
    };
  },
  methods: {
    onNewProductInfoResult(res) {
      this.productInfoResult = res;

      if (res && res.productFound) {
        VueScrollTo.scrollTo('#index-checker-result-scroll-anchor', 500, { offset: -170 });
      }
    },
    onNewKeywordResult(res) {
      let keywordObject = this.productInfoResult.keywords.find(kwObj => kwObj.keyword === res.keyword);
      keywordObject.isIndexed = res.isIndexed;
    }
  }
};
</script>

<style scoped lang="scss">
.feature-content h3 {
  font-weight: 900;
  line-height: 61px;
  font-size: 48px;
  color: #ffaf25;
}

.feature-content p {
  font-weight: 900;
  line-height: 32px;
  font-size: 20px;
  color: #2d7a63;
}

.feature-content li {
  line-height: 34px;
  font-size: 18px;
  color: #6c6c6c;
  margin-bottom: 0px;
}

.heading-desc {
  font-weight: 900;
  font-size: 20px;
  line-height: 27px;
  color: #ffbf3c;

  .heading-desc-instructions {
    font-size: 18px;
    line-height: 30px;
    color: #6c6c6c;
    margin-top: 5px;
  }
}

.feature-panel .feature-content ul li:before {
  color: #5cbc95;
}

.hero {
  padding-top: 130px;
  padding-bottom: 90px;
}
</style>