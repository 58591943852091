
const markets = [
    { region: 'North America', abbreviation: 'US', name: 'United States', tld: '.com' },
    { region: 'North America', abbreviation: 'CA', name: 'Canada', tld: '.ca' },
    { region: 'North America', abbreviation: 'MX', name: 'Mexico', tld: '.com.mx' },

    { region: 'Europe', abbreviation: 'FR', name: 'France', tld: '.fr' },
    { region: 'Europe', abbreviation: 'DE', name: 'Germany', tld: '.de' },
    { region: 'Europe', abbreviation: 'IT', name: 'Italy', tld: '.it' },
    { region: 'Europe', abbreviation: 'ES', name: 'Spain', tld: '.es' },
    { region: 'Europe', abbreviation: 'UK', name: 'United Kingdom', tld: '.co.uk' },

    { region: 'Australia', abbreviation: 'AU', name: 'Australia', tld: '.com.au' },

    { region: 'India', abbreviation: 'IN', name: 'India', tld: '.in' },

];

const MarketService = {
    getAll() {
        return markets;
    },
    getAllByRegions() {
        let markets = this.getAll();

        let result = markets.reduce((acc, val) => {
            if (!acc[val.region]) {
                acc[val.region] = { 
                    name: val.region,
                    markets: [],
                };
            }

            acc[val.region].markets.push(val);
            return acc;
        }, {});

        return result;
    },
    getMarket(marketAbbr) {
        return markets.find(m => m.abbreviation === marketAbbr);
    },
    getProductUrl(marketAbbr, asin) {
        let market = this.getMarket(marketAbbr);
        return `https://www.amazon${market.tld}/dp/${asin}`;
    },
    getFlagUrl(market) {
        return `/assets/images/flags/${market.abbreviation}.png`;
    }
}

export default MarketService;