import ApiService from './api.service';

const IndexCheckerService = {
    check(data) {
        return ApiService.post('/IndexChecker/Check', data);
    },
    fetchProductInfo(asin, market) {
        let data = { asin: asin, market: market };
        return ApiService.post('/IndexChecker/ProductInfo', data);
    }
}

export default IndexCheckerService;