<template>
  <header class="header header-1 header-transparent header-fixed">
    <nav class="navbar navbar-expand-lg navbar-dark">
      <div class="container">
        <a class="navbar-brand" href="https://zonguru.com">
          <img class="logo logo-dark" src="@/assets/images/logo-home.svg" alt="ZonGuru Logo" />
        </a>
        <button
          class="navbar-toggler"
          type="button"
          aria-controls="navbarContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
          :class="{'collapsed': navCollapsed, 'toggler-active': !navCollapsed}"
          @click="toggleNavbar"
        >
          <span class="toogle-inner"></span>
        </button>
        <div class="collapse navbar-collapse" :class="{'show': !navCollapsed }">
          <ul class="navbar-nav ml-auto">
            <li class="active">
              <a href="https://zonguru.com/about-us/">About</a>
            </li>
            <li>
              <a class="bordered-link" href="https://zonguru.com/pricing/">Try ZonGuru for Free</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  </header>
</template>

<script>
export default {
  name: "AppHeader",
  data: function() {
    return {
      navCollapsed: true
    };
  },
  methods: {
    toggleNavbar() {
      this.navCollapsed = !this.navCollapsed;
    }
  }
};
</script>

<style scoped lang="scss">
.navbar-nav > li > a {
  padding: 8px 15px 8px 15px;
  font-weight: 500;
  line-height: 20px;
  font-size: 16px;
  text-align: center;
  color: #6c6c6c !important;
}

.bordered-link {
  border: 1px solid #ffbf3c;
  box-sizing: border-box;
  border-radius: 5px;
}

.navbar .navbar-toggler {
  top: 30px;
  right: 30px;
}

.toogle-inner,
.toogle-inner:before,
.toogle-inner:after {
  background-color: #6c6c6c;
}
</style>