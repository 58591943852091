<template>
  <section class="app-testimonials section reviews pt-0 pb-0 bg-white">
    <div class="container">
      <div class="row">
        <div class="col-12 col-sm-12 col-md-12 col-lg-8 offset-lg-2">
          <h3 class="testimonials-title">What customers are saying</h3>
          <div class="carousel carousel-dots owl-carousel">
            <!-- Testimonial #1 -->
            <div class="testimonial-panel">
              <div class="testimonial-body">
                <p>The ZonGuru ASIN Index Checker gave me amazing insight into which keywords Amazon correlates with my listing. This free tool showed that my listings weren’t optimized and there was serious room for improvement. I noticed ZonGuru has a few different keyword tools so I signed up for their free trial and started using their keyword tracking and keyword research tools and all of a sudden my listing is visible all over Amazon. Investing in ZonGuru paid for itself almost immediately.</p>
                <div class="testimonial-author">
                  <h5 class="bold">Liz Wellzner</h5>
                  <h6>Private Label Seller</h6>
                </div>
              </div>
            </div>

            <!-- Testimonial #2  -->
            <div class="testimonial-panel">
              <div class="testimonial-body">
                <p>This Index Checker is awesome! I found that my listing is indexed for most of my main buyer keywords - but there were a few I didn’t realize were missing. Now I’m tracking all of my keywords inside ZonGuru’s Keyword Tracking tool so I’m not left guessing how my keywords are performing. Glad I found ZonGuru through this free tool.</p>
                <div class="testimonial-author">
                  <h5 class="bold">Isamer Ramirez</h5>
                  <h6>Certified Amazon Seller</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>


<script>
export default {
  name: "AppTestimonials",
  components: {},
  mounted: function() {
    // owl carousel is installed with the theme.. so we need to initialize it here..
    // eslint-disable-next-line
    $(".carousel").owlCarousel({
      loop: true,
      autoplay: false,
      margin: 0,
      navigation: false,
      dots: true,
      dotsSpeed: 1000,
      responsive: {
        0: { items: 1 }
      }
    });
  }
};
</script>

<style scoped lang="scss">
.testimonials-title {
  line-height: 45px;
  font-size: 36px;
  text-align: center;
  color: #2d7a63;
}

.testimonial-panel {
  &:before {
    width: 70px;
    height: 60px;
    background-size: 70px;
    background-image: url(../assets/images/icons/quotes.svg);
  }
  
  .testimonial-body {
    p {
      line-height: 35px;
      font-size: 18px;
      text-align: center;
      color: #6c6c6c;
      margin-bottom: 30px;
    }
  }
}

.testimonial-author {
  h5 {
    font-weight: bold;
    font-size: 18px;
    color: #2d7a63;
  }

  h6 {
    font-weight: normal;
    font-size: 16px;
    color: #2d7a63;
    margin-top: 5px;
  }
}
</style>

<style>
.carousel-dots .owl-dots .owl-dot span {
  border: 1px solid #2d7a63;
}

.carousel-dots .owl-dots .owl-dot.active span {
  background-color: #2d7a63;
  border-color: #2d7a63;
}
</style>