<template>
  <div class="app-advertisement">
    <section class="section feature pb-0 pt-0">
      <div class="container">
        <div class="row">
          <div class="col-12 col-md-12 offset-lg-1 col-lg-4">
            <div class="feature-panel">
              <div class="feature-content">
                <h3 class="zg-main-title">Best used for:</h3>
                <p class="zg-main-desc">Listing Optimization, Keyword Targeting to Drive More Sales, and Much More!</p>
              </div>
            </div>
          </div>
          <div class="col-12 col-md-12 offset-lg-1 col-lg-6 pb-5">
            <div class="feature-panel card">
              <div class="feature-content card-body">
                <h3 class="zg-title">Get Answers to Questions like:</h3>
                <ul class="zg-options">
                  <li>Can my customers find my listing when they type a certain phrase or keyword?</li>
                  <li>Which keywords should I add to my backend keywords to become more visible than my competitors?</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="section feature feature-2 bg-shape">
      <div class="container">
        <div class="clearfix mt-20"></div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-6 text-center">
            <img class="zg-cycle img-fluid" src="../assets/images/circle_tool.svg" alt="device" />
          </div>
          <div class="col-12 col-md-12 col-lg-6">
            <div class="feature-panel pt-100">
              <div class="feature-content">
                <h3 class="zg-title">Want to try ZonGuru for FREE?</h3>
                <p class="zg-desc">Check out all of our tools that will help you grow at every phase of your Amazon business</p>
                <ul class="zg-main-options">
                  <li>Accurate, Timely and Relevant Data</li>
                  <li>Built by Expert Amazon Sellers</li>
                  <li>From $29/mo</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 col-md-12 col-lg-12 text-center">
            <div class="feature-panel mt-20">
              <div class="feature-content">
                <a href="https://zonguru.com/pricing/" class="zg-btn btn btn--gradient">Start Your 7 days FREE Trial</a>
              </div>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12 text-center">
            <div class="feature-panel mt-20">
              <div class="feature-content">
                <a class="zg-link" href="https://zonguru.com/">Go to zonguru.com to learn more</a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>


<script>
export default {
  name: "AppAdvertisement"
};
</script>

<style scoped lang="scss">
.app-advertisement {
  .bg-shape {
    background: rgba(255, 191, 60, 0.2);
    padding-top: 100px;
    padding-bottom: 130px;
  }

  .feature-content {
    .zg-main-title {
      text-transform: uppercase;
      font-weight: bold;
      line-height: 32px;
      font-size: 32px;
      color: #ffaf25;
    }

    .zg-main-desc {
      font-weight: bold;
      line-height: 37px;
      font-size: 26px;
      color: #2d7a63;
    }

    .zg-title {
      font-weight: bold;
      line-height: 32px;
      font-size: 32px;
      color: #2d7a63;
    }

    .zg-options {
      padding-left: 15px;

      > li {
        line-height: 32px;
        font-size: 18px;
        color: #6c6c6c;

        &:before {
          font-family: "fontawesome";
          content: "\f111";
          font-size: 12px;
          position: absolute;
          left: 10px;
          top: 2px;
        }
      }
    }

    .zg-desc {
      font-weight: normal;
      line-height: 28px;
      font-size: 18px;
      color: #2d7a63;
    }

    .zg-main-options > li {
      font-weight: bold;
      line-height: 25px;
      font-size: 18px;
      color: #2d7a63;
    }

    .zg-link {
      font-weight: 900;
      line-height: 20px;
      font-size: 16px;
      text-align: center;
      text-decoration-line: underline;
      color: #2d7a63;
    }
  }

  .card {
    box-shadow: 3px 4px 16px rgba(0, 0, 0, 0.15);
  }

  .zg-cycle {
    max-width: 80%;
  }

  .zg-btn {
    background: #ffaf25;
    width: 300px;
    height: 66px;
    line-height: 66px;
    font-size: 20px;
    text-transform: none;
  }
}
</style>