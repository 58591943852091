<template>
  <div>
    <form novalidate="true" @submit.prevent="getResults">
      <div class="form-row mb-20">
        <div class="col-6 pr-10">
          <input
            type="text"
            v-model="asin"
            class="form-control"
            autofocus
            placeholder="Type product ASIN..."
            minlength="10"
            maxlength="10"
            :class="{ 'is-invalid': errors.asin }"
          />
          <div class="form-error" v-if="errors.asin">{{errors.asin}}.</div>
        </div>
        <div class="col-6 pl-10">
          <select
            v-model="marketplace"
            class="form-control"
            autocomplete="off"
            :class="{ 'is-invalid': errors.marketplace }"
          >
            <optgroup v-for="region in regions" :key="region.name" :label="region.name">
              <option
                v-for="market in region.markets"
                :key="market.abbreviation"
                :value="market.abbreviation"
              >{{market.name}} ({{market.abbreviation}})</option>
            </optgroup>
          </select>
          <div class="form-error" v-if="errors.marketplace">{{errors.marketplace}}.</div>
        </div>
      </div>

      <div class="form-row mb-20">
        <div class="col">
          <textarea
            v-model="keywords"
            class="form-control"
            placeholder="Type some keywords or keyphrases on new lines"
            autocomplete="off"
            :class="{ 'is-invalid': errors.keywords }"
          ></textarea>
          <div class="form-error" v-if="errors.keywords">{{errors.keywords}}.</div>
        </div>
      </div>

      <input type="submit" value="Get Results" class="btn btn--gradient" :disabled="loading" />
    </form>

    <AppEmailModalComponent ref="emailModal" @subscribed="onSubscribe"></AppEmailModalComponent>
  </div>
</template>

<script>
let debounce = require("debounce");

import IndexCheckerService from "../services/indexChecker.service";
import MarketService from "../services/market.service";
import AppEmailModalComponent from "./AppEmailModalComponent.vue";

export default {
  name: "AppIndexCheckerForm",
  props: {},
  components: {
    AppEmailModalComponent
  },
  data: function() {
    return {
      loading: false,
      submitted: false,
      asin: null,
      marketplace: "US",
      keywords: null,
      errors: {
        asin: null,
        marketplace: null,
        keywords: null
      }
    };
  },
  computed: {
    regions() {
      return MarketService.getAllByRegions();
    }
  },
  watch: {
    asin: function() {
      if (this.submitted) {
        debounce(this.validateAsin(), 300);
      }
    },
    marketplace: function() {
      if (this.submitted) {
        debounce(this.validateMarketplace(), 300);
      }
    },
    keywords: function() {
      if (this.submitted) {
        debounce(this.validateKeywords(), 300);
      }
    }
  },
  methods: {
    getResults() {
      this.submitted = true;

      let isValid =
        this.validateAsin() &
        this.validateMarketplace() &
        this.validateKeywords();

      if (!isValid) {
        return;
      }

      let usage = this.getUsage();

      if (usage.numberOfUses > 0 && !usage.email) {
        // if the user has a VisitorId that means he's a Visitor or Lead and we will ask him for his email address
        // if VisitorId is undefined then this is a registered user so we already have his email and we don't need to ask him to subscribe.
        let intercomVisitorId = this.$intercom.getVisitorId();
        if (intercomVisitorId) {
          this.$refs.emailModal.show();
        } else {
          this.onSubscribe();
        }
      } else {
        this.fetchIndexCheckData();
      }
    },
    validateAsin() {
      if (!this.asin || this.asin.length !== 10) {
        this.errors.asin = "Please enter a valid ASIN";
        return false;
      }

      this.errors.asin = null;
      return true;
    },
    validateMarketplace() {
      if (!this.marketplace) {
        this.errors.marketplace = "Please select a marketplace";
        return false;
      }

      this.errors.marketplace = null;
      return true;
    },
    validateKeywords() {
      if (!this.keywords || !this.keywords.length) {
        this.errors.keywords = "Please enter at least one keyword";
        return false;
      }

      let keywordsArray = this.keywords.split("\n");
      if (keywordsArray.length > 20) {
        this.errors.keywords = "Please enter at most 20 keywords";
        return false;
      }

      this.errors.keywords = null;
      return true;
    },
    getUsage() {
      let usage = this.$localStorage.get("zg-index-checker-usage") || {};
      usage.numberOfUses = usage.numberOfUses || 0;
      usage.email = usage.email || null;
      return usage;
    },
    saveUsage(incrementUsage, newEmail) {
      let usage = this.getUsage();
      if (incrementUsage) {
        usage.numberOfUses += 1;
      }
      if (newEmail) {
        usage.email = newEmail;
      }

      this.$localStorage.set("zg-index-checker-usage", usage);
    },
    fetchIndexCheckData() {
      this.loading = true;
      let mainLoader = this.$loading.show();

      IndexCheckerService.fetchProductInfo(this.asin, this.marketplace)
        .then(res => {
          let result = res.data || {};

          if (result.productFound) {
            this.saveUsage(true); // increment usage
          }

          result.keywords = this.keywords
            .split("\n")
            .map(kw => ({
              keyword: kw.trim().replace(/,+$/, ""), // Replace last comma if any
              isIndexed: null
            }))
            .filter(kwObj => !!kwObj.keyword); // Remove empty strings

          this.$emit("new-product-info-result", result);

          // if product is not found don't execute index check calls
          if (!result.productFound) {
            this.loading = false;
            return;
          }

          let keywordsCount = result.keywords.length;
          let indexCheckerCompletedRequests = 0;
          for (let i = 0; i < keywordsCount; i++) {
            let kwObj = result.keywords[i];

            let payload = {
              asin: this.asin,
              market: this.marketplace,
              keyword: kwObj.keyword
            };

            let startTime = performance.now();
            IndexCheckerService.check(payload)
              .then(kwRes => {
                let newKeywordresult = {
                  keyword: payload.keyword,
                  isIndexed: kwRes.data ? kwRes.data.isIndexed : false
                };

                let endTime = performance.now();
                let ellapsed = endTime - startTime;
                // if the ajax call executes in less than 1500 ms, add a delay to make impression that 
                // something heavy is being worked on and also to throttle user requests.
                if (ellapsed < 1500) {
                  let delay = 1500 + i * 300;
                  if (delay <= 1000) delay = 1000;
                  if (delay >= 6000) delay = 6000;
                  setTimeout(
                    () => this.$emit("new-keyword-result", newKeywordresult),
                    delay
                  );
                } else {
                  this.$emit("new-keyword-result", newKeywordresult);
                }
              })
              .catch(() => {
                let newKeywordresult = {
                  keyword: payload.keyword,
                  isIndexed: false
                };
                this.$emit("new-keyword-result", newKeywordresult);
              })
              .finally(() => {
                indexCheckerCompletedRequests++;
                if (indexCheckerCompletedRequests >= keywordsCount) {
                  this.loading = false;
                }
              });
          }
        })
        .catch(() => { 
          this.$emit("new-product-info-result", {});
          this.loading = false;
        })
        .finally(() => mainLoader.hide());
    },
    onSubscribe(emailAddress) {
      if (emailAddress) {
        this.$intercom.update({ anonymous_email: emailAddress });
      }

      this.$intercom.trackEvent("subscribed-on-index-checker-website");
      this.saveUsage(false, emailAddress || "--intercom-user--");
      this.fetchIndexCheckData();
    }
  }
};
</script>

<style scoped lang="scss">
</style>