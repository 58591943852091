import Vue from 'vue';
import VueGtm from 'vue-gtm';
import VueIntercom from 'vue-intercom';
import VueLoadingOverlay from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { ModalPlugin } from 'bootstrap-vue';
import VueSlimScroll from 'vue-slimscroll';
import VueLocalStorage from 'vue-local-storage';
import App from './App.vue';
import ApiService from './services/api.service';

Vue.use(VueGtm, { id: process.env.VUE_APP_GTM_ID, enabled: !!process.env.VUE_APP_GTM_ID })
Vue.use(VueIntercom, { appId: process.env.VUE_APP_INTERCOM_ID});
Vue.use(VueLoadingOverlay);
Vue.use(ModalPlugin);
Vue.use(VueSlimScroll);
Vue.use(VueLocalStorage, { name: 'zg' });
ApiService.init(process.env.VUE_APP_ROOT_API);
Vue.config.productionTip = false;

new Vue({
  render: h => h(App),
}).$mount('#app');